import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="arrival-window-select"
export default class extends Controller {
  updateArrivalTimes() {
    const arrival_window = this.element.value

    let timeValues = {
      startHour: undefined,
      startMinutes: undefined,
      endHour: undefined,
      endMinutes: undefined
    };

    switch (arrival_window) {
      case "morning":
        timeValues.startHour = 6;
        timeValues.startMinutes = 30;
        timeValues.endHour = 11;
        timeValues.endMinutes = 45;
        break
      case "afternoon":
        timeValues.startHour = 12;
        timeValues.startMinutes = 0;
        timeValues.endHour = 16;
        timeValues.endMinutes = 15;
        break
      case "evening":
        timeValues.startHour = 17;
        timeValues.startMinutes = 0;
        timeValues.endHour = 20;
        timeValues.endMinutes = 45;
        break
    }

    this.refreshDropdownValues(timeValues);
  }

  refreshDropdownValues({startHour, startMinutes, endHour, endMinutes}) {
    document.getElementById("arrivalTimes").innerHTML = '<option value="">Select Arrival Time (optional)</option>';
    const start = new Date();
    start.setHours(startHour, startMinutes, 0);
    const end = new Date();
    end.setHours(endHour, endMinutes, 0);  
    
    while (start <= end) {
      const opt = start.toLocaleString('en-US', {hour: '2-digit', minute: '2-digit'});
      document.getElementById("arrivalTimes").innerHTML += "<option value=\"" + opt + "\">" + opt + "</option>";
      start.setMinutes(start.getMinutes() + 15);
    }
  }  
}