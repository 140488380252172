import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="application-shell-layout"
export default class extends Controller {
  static targets = [ "sidebarComponent", "overlayBackdrop", "sidebarMenu", "sidebarCloseButton", "modal", "slideOverComponent", "slideOverPanel" ];

  openSidebar() {
    this.allSidebarTargets.forEach(target => enter(target))
  }

  closeSidebar() {
    this.allSidebarTargets.forEach(target => leave(target))
  }

  openModal() {
    this.allModalTargets.forEach(target => enter(target))
  }

  closeModal() {
    this.allModalTargets.forEach(target => leave(target))
  }

  openSlideOver() {
    this.allSlideOverTargets.forEach(target => enter(target))
  }

  closeSlideOver() {
    this.allSlideOverTargets.forEach(target => leave(target))
  }

  overlayClicked() {
    this.closeModal();
    this.closeSlideOver();
  }

  get allSidebarTargets() {
    return [
      this.sidebarComponentTarget,
      this.overlayBackdropTarget,
      this.sidebarMenuTarget,
      this.sidebarCloseButtonTarget
    ]
  }

  get allModalTargets() {
    return [ 
      this.modalTarget,
      this.overlayBackdropTarget
    ]
  }

  get allSlideOverTargets() {
    return [
      this.slideOverComponentTarget,
      this.slideOverPanelTarget,
      this.overlayBackdropTarget
    ]
  }
}
