import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.css";

// Connects to data-controller="dashboard-date"
export default class extends Flatpickr {
  change(selectedDates) {
    const date = selectedDates[0];
    const formattedDate = date.toISOString().split('T')[0];
    const currentPath = window.location.pathname;
    Turbo.visit(`${currentPath}?date=${formattedDate}`);
  }
}
