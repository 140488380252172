import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ["filter", "flatpickrFilter"]

  clear() {
    this.filterTargets.forEach((filter) => {
      filter.value = ""
    });
    this.flatpickrFilterTargets.forEach((flatpickrFilter) => {
      const flatpickrController = this.application.getControllerForElementAndIdentifier(flatpickrFilter, "flatpickr");
      flatpickrController.fp.clear();
    });
    this.dispatch("cleared")
  }
}
