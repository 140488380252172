import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-range-filter"
export default class extends Controller {
  static targets = ["range", "minDate", "maxDate"]

  connect() {
  }

  change() {
    const split_value = this.rangeTarget.value.split(" to ");

    this.minDateTarget.value = split_value[0];
    this.maxDateTarget.value = split_value.length == 2 ? split_value[1] : split_value[0];

    this.dispatch("filter");
  }
}
