import ColorPicker from "stimulus-color-picker";
import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";

export default class extends ColorPicker {
  connect() {
    this.picker = Pickr.create({
      el: this.buttonTarget,
      theme: "nano",
      default: this.inputTarget.value,
      swatches: this.swatches,
      components: this.componentOptions
    });

    this.picker.on("save", this.onSave);
  }

  get componentOptions() {
    let componentOptions = super.componentOptions
    componentOptions.preview = false
    componentOptions.opacity = false
    componentOptions.palette = false
    componentOptions.hue = false
    componentOptions.interaction.input = false
    // componentOptions.interaction.save = false
    componentOptions.interaction.clear = false
    return componentOptions;
  }

  get swatches() {
    return [
      "#346557", // green
      "#B4322A", // red
      "#CEAC69", // yellow
      "#C4725D", // orange
      "#4A6E96", // dark blue
      "#7094BD" // light blue
    ]
  }
}