import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refinement"
export default class extends Controller {
  static targets = ["form", "search", "filter", "flatpickrFilter"];

  clearFilters(event) {
    this.filterTargets.forEach((filter) => {
      filter.value = "";
    });
    this.flatpickrFilterTargets.forEach((flatpickrFilter) => {
      const flatpickrController = this.application.getControllerForElementAndIdentifier(flatpickrFilter, "flatpickr");
      flatpickrController.fp.clear();
    });
    this.refineResults(event);
    this.dispatch("filtersCleared");
  }

  refineResults(event) {
    if (event.target.classList.contains("page")) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const paginationUrl = event.target.href;
      const searchParams = new URL(paginationUrl).searchParams;

      if (searchParams.has("page")) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "page";
        input.value = searchParams.get("page");
        this.formTarget.appendChild(input);
      }

      if (searchParams.has("limit")) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "limit";
        input.value = searchParams.get("limit");
        this.formTarget.appendChild(input);
      }
    }

    if (this.hasSearchTarget && !!this.searchTarget.value) {
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = "search";
      input.value = this.searchTarget.value;
      this.formTarget.appendChild(input);
    }

    if (this.hasFilterTarget) {
      this.filterTargets.forEach((filterTarget) => {
        if (!!filterTarget.value) {
          let input = document.createElement("input");
          input.type = "hidden";
          input.name = filterTarget.name;
          input.value = filterTarget.value;
          this.formTarget.appendChild(input);
        }
      });
    }

    this.formTarget.requestSubmit();
  }

  refineResultsDebounced(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.refineResults(event)
    }, 200);
  }
}