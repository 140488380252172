import '@hotwired/turbo-rails';
// import "@turbo-boost/commands";

import "@/init/stimulus";
import "@/init/active_storage";
import "@/controllers";

import "@fontsource/pt-serif";
import "@fontsource-variable/inter";

import "@/images/garner-acorn.png";

import { StreamActions } from "@hotwired/turbo";

StreamActions.visit = function() {
  const path = this.getAttribute("path")
  Turbo.visit(path)
}

StreamActions.scroll_to_top = function() {
  const frame = this.targetElements[0];
  if (frame) {
    frame.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}